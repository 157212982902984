<template>
  <footer>
    <h1>
      <router-link to="/">
        MAKE<br />
        GREATNESS<br />
        <span class="e-motion">e <span>.</span> motion</span>
      </router-link>
    </h1>

    <privacy :dialogVisible.sync="dialogVisible" />
    <div class="info-box">
      <a href="#" @click.prevent="dialogVisible = true">개인정보취급방침</a>
      <p class="copy">ⓒ e <span>.</span> motion Global Inc. All rights reserved.</p>
    </div>
    <button type="button" class="btn-top" @click="winTop">top</button>
  </footer>
</template>
<script>
  import privacy from "@/views/privacy/index";
  export default {
    name: "AppFooter",
    data() {
      return {
        dialogVisible: false
      };
    },
    components: {
      privacy
    },
    methods: {
      winTop() {
        window.scrollTo({top: 0, behavior: "smooth"});
      }
    }
  };
</script>
<style lang="scss" scoped>
  footer {
    position: relative;
    @include globalWidth(min-width);
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    color: #a0a0a0;
    h1 {
      @include montserrat();
      font-size: 10px;
      color: $red;
      line-height: 12px;
      letter-spacing: 0;
      a {
        color: $red;
      }
    }
    .info-box {
      margin: -7px 35px 0 0;
      text-align: right;
      a {
        display: inline-block;
        padding: 7px 0;
        color: inherit;
      }
      .copy {
        @include montserrat();
      }
    }
    .btn-top {
      z-index: 100;
      position: absolute;
      top: 50%;
      right: 11px;
      width: 30px;
      height: 30px;
      border: none;
      outline: none;
      text-indent: -9999px;
      overflow: hidden;
      background: #fff;
      transform: translateY(-50%);
      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 13px;
        height: 19px;
        background: url(../assets/images/btn-top.png) no-repeat center / cover;
      }
    }
  }
</style>
